import en from "./locales/en.json";
import fr from "./locales/fr.json";

export default {
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  availableLocales: ["en", "fr"],
  messages: {
    en,
    fr,
  },
};
